import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Typography, TextField, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, Paper, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

const PromoCodeManagement = () => {
  const [promoCode, setPromoCode] = useState('');
  const [checkResult, setCheckResult] = useState(null);
  const [newPromoCode, setNewPromoCode] = useState('');
  const [discountType, setDiscountType] = useState('percentage');
  const [discountValue, setDiscountValue] = useState('');
  const [promoCodes, setPromoCodes] = useState([]);
  const [creationMessage, setCreationMessage] = useState('');
  const [deleteConfirmation, setDeleteConfirmation] = useState(null); // Gère la confirmation de suppression

  useEffect(() => {
    fetchPromoCodes();
  }, []);

  const fetchPromoCodes = () => {
    axios.get('https://joli-mot.fr/apiEtsy.php?cleApi=34KVHFQSP226YTk4n256QP6rGbqhA5w2ymY7W3pLwdbuc3i75&service=getAllPromoCodes')
      .then(response => {
        if (response.data.success) {
          setPromoCodes(response.data.promoCodes);
        } else {
          console.error('Erreur lors de la récupération des codes promo:', response.data.message);
        }
      })
      .catch(error => console.error('Erreur lors de la récupération des codes promo:', error));
  };

  const handleCheckPromoCode = () => {
    axios.get(`https://joli-mot.fr/apiEtsy.php?cleApi=34KVHFQSP226YTk4n256QP6rGbqhA5w2ymY7W3pLwdbuc3i75&service=checkPromoCode&promoCode=${promoCode}`)
      .then(response => {
        setCheckResult(response.data);
      })
      .catch(error => {
        console.error('Erreur lors de la vérification du code promo:', error);
        setCheckResult({ success: false, message: 'Erreur de vérification' });
      });
  };

  const handleCreatePromoCode = () => {
    axios.get(`https://joli-mot.fr/apiEtsy.php?cleApi=34KVHFQSP226YTk4n256QP6rGbqhA5w2ymY7W3pLwdbuc3i75&service=createPromoCode&code=${newPromoCode}&discount_type=${discountType}&discount_value=${discountValue}`)
      .then(response => {
        if (response.data.success) {
          setCreationMessage('Code promo créé avec succès');
          fetchPromoCodes(); // Mise à jour de la liste des codes promo
        } else {
          setCreationMessage(`Erreur : ${response.data.message}`);
        }
      })
      .catch(error => {
        console.error('Erreur lors de la création du code promo:', error);
        setCreationMessage('Erreur lors de la création du code promo');
      });
  };

  const handleDeletePromoCode = (code) => {
    axios.get(`https://joli-mot.fr/apiEtsy.php?cleApi=34KVHFQSP226YTk4n256QP6rGbqhA5w2ymY7W3pLwdbuc3i75&service=deletePromoCode&promoCode=${code}`)
      .then(response => {
        if (response.data.success) {
          setPromoCodes(prevCodes => prevCodes.filter(promo => promo.code !== code));
          setDeleteConfirmation(null);
        } else {
          console.error('Erreur lors de la suppression du code promo:', response.data.message);
        }
      })
      .catch(error => {
        console.error('Erreur lors de la suppression du code promo:', error);
      });
  };

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h1" gutterBottom>Gestion des Codes Promo</Typography>

      {/* Vérification d'un code promo */}
      <Paper style={{ padding: '20px', marginBottom: '20px' }}>
        <Typography variant="h6" gutterBottom>Vérifier un Code Promo</Typography>
        <TextField
          label="Code Promo"
          value={promoCode}
          onChange={(e) => setPromoCode(e.target.value)}
          style={{ marginRight: '10px' }}
        />
        <Button variant="contained" color="primary" onClick={handleCheckPromoCode}>Vérifier</Button>

        {checkResult && (
          <Typography style={{ marginTop: '10px', color: checkResult.success ? 'green' : 'red' }}>
            {checkResult.success
              ? `Code valide : ${checkResult.discount_type} de ${checkResult.discount_value}`
              : checkResult.message}
          </Typography>
        )}
      </Paper>

      {/* Création d'un nouveau code promo */}
      <Paper style={{ padding: '20px', marginBottom: '20px' }}>
        <Typography variant="h6" gutterBottom>Créer un Nouveau Code Promo</Typography>
        <TextField
          label="Nouveau Code Promo"
          value={newPromoCode}
          onChange={(e) => setNewPromoCode(e.target.value)}
          style={{ marginRight: '10px' }}
        />
        <Select
          value={discountType}
          onChange={(e) => setDiscountType(e.target.value)}
          style={{ marginRight: '10px' }}
        >
          <MenuItem value="percentage">Pourcentage</MenuItem>
          <MenuItem value="fixed">Montant Fixe</MenuItem>
        </Select>
        <TextField
          label="Valeur de la Réduction"
          type="number"
          value={discountValue}
          onChange={(e) => setDiscountValue(e.target.value)}
          style={{ marginRight: '10px' }}
        />
        <Button variant="contained" color="secondary" onClick={handleCreatePromoCode}>Créer</Button>

        {creationMessage && (
          <Typography style={{ marginTop: '10px', color: creationMessage.includes('succès') ? 'green' : 'red' }}>
            {creationMessage}
          </Typography>
        )}
      </Paper>

      {/* Liste des codes promo existants */}
      <Paper style={{ padding: '20px' }}>
        <Typography variant="h6" gutterBottom>Codes Promo Existants</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>Type de Réduction</TableCell>
              <TableCell>Valeur</TableCell>
              <TableCell>Date de Création</TableCell>
              <TableCell>Statut</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {promoCodes.map((promo, index) => (
              <TableRow key={index}>
                <TableCell>{promo.code}</TableCell>
                <TableCell>{promo.discount_type === 'percentage' ? 'Pourcentage' : 'Fixe'}</TableCell>
                <TableCell>{promo.discount_value}</TableCell>
                <TableCell>{new Date(promo.created_at).toLocaleDateString()}</TableCell>
                <TableCell>{promo.is_active ? 'Actif' : 'Inactif'}</TableCell>
                <TableCell>
                  <Button variant="contained" color="error" onClick={() => setDeleteConfirmation(promo.code)}>
                    Supprimer
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>

      {/* Confirmation de suppression */}
      <Dialog open={!!deleteConfirmation} onClose={() => setDeleteConfirmation(null)}>
        <DialogTitle>Confirmer la Suppression</DialogTitle>
        <DialogContent>
          <Typography>Voulez-vous vraiment supprimer le code promo {deleteConfirmation} ?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmation(null)} color="secondary">Annuler</Button>
          <Button onClick={() => handleDeletePromoCode(deleteConfirmation)} color="error">Supprimer</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PromoCodeManagement;
